import { useNetwork, useNotification, useSharedVariables } from 'hooks';
import {
	ChangeEvent,
	DragEvent,
	useCallback,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';
import { BodyNameKeyType, IFormProperties } from 'states';
import {
	convertFileToBase64,
	formatBase64,
	isMobileDevice,
	isValidImage,
} from 'utils';
import { ImageProcess } from 'utils/image-process';
import {
	formPropertiesState,
	kycActiveStepName,
	KycDocIsGlare,
	KycGlareErrors,
	kycRenderedObj,
	KyCurrentImage,
} from 'views/kyc/components/document-verification-wrapper/store';
import { IsKycLoading, useKycRequests } from 'views/kyc/stores';
import {
	dataURItoBlob,
	drawRectangle,
	getVideoStream,
	handleUploadImage,
	imageRotateHandle,
	renderCaptureImage,
	stopCamera,
} from '../../store/utils';
import { pipelineMobileScreenBodySettings } from 'views/kyc/constants';

export type ImageKey =
	| 'frontImage'
	| 'backImage'
	| 'frontImagePayload'
	| 'backImagePayload'
	| 'selfie';

export const UseVerification = (
	bodyName: BodyNameKeyType,
	keyName: ImageKey = 'frontImage'
) => {
	const [formProperties, setFormProperties] =
		useRecoilState<IFormProperties>(formPropertiesState);
	const [activeStepName, setActiveStepName] = useRecoilState(kycActiveStepName);
	const renderedObj = useRecoilValue(kycRenderedObj);
	const [isLoading, setIsLoading] = useRecoilState(IsKycLoading);
	const setIsGlare = useSetRecoilState(KycDocIsGlare);
	const [glareErrors, setGlareErrors] =
		useRecoilState<string[]>(KycGlareErrors);
	const [currentImage, setCurrentImage] = useRecoilState(KyCurrentImage);
	const reSetCurrentImage = useResetRecoilState(KyCurrentImage);
	const resetFormState = useResetRecoilState(formPropertiesState);

	const [filledCamerascreen, setFilledcamerascreen] = useState(false);
	const [cameraWidth, setcameraWidth] = useState(0);
	const [cameraHeight, setcameraHeight] = useState(0);
	const [cameraScreenActive, setCamerascreenActive] = useState(false);

	const canvasRef = useRef<HTMLCanvasElement>(null);
	const captureRef = useRef<HTMLDivElement>(null);
	const videoRef = useRef<HTMLVideoElement>(null);
	const maxCameraTryCountRef = useRef(0); // cmera open try count, clear to 0 zero if its open
	const drawPlaceholder = useRef({ x: 0, y: 0, height: 0, width: 0 });

	const { apiEndPoint: API_HOST } = useSharedVariables();
	const { docType, handleKycDocVerification } = useKycRequests();
	const { post: rotatePostAPI } = useNetwork();
	const { errorNotification } = useNotification();

	const verificationContent = useMemo(
		() => pipelineMobileScreenBodySettings[bodyName],
		[bodyName]
	) as any;

	const { label } = useMemo(() => renderedObj ?? {}, [renderedObj]);

	const onClickUploadButton = useCallback(
		async (
			e: ChangeEvent<HTMLInputElement> | { target: any },
			key: ImageKey
		) => {
			const file = (e.target as HTMLInputElement).files?.[0];

			if (file) {
				setIsLoading(true);
				const isValid = isValidImage(file.name);
				if (isValid) {
					const base64Image: any = await convertFileToBase64(file);
					const dataImage = await ImageProcess(base64Image);

					const payload = {
						image: formatBase64(dataImage),
					};
					let currentImageUrl = payload?.image;
					await imageRotateHandle(
						payload,
						rotatePostAPI,
						API_HOST,
						setCurrentImage
					).then(convertedImg => {
						if (convertedImg) {
							currentImageUrl = convertedImg;
						}
					});
					setCurrentImage(currentImageUrl);
					handleUploadImage(
						{ image: currentImageUrl, base64Image, key },
						setFormProperties,
						file.name
					);
				} else errorNotification('Please Select Valid Image');
			}
			e.target.value = '';

			setIsLoading(false);
		},
		[
			setIsLoading,
			errorNotification,
			setFormProperties,
			rotatePostAPI,
			API_HOST,
			setCurrentImage,
		]
	);

	const handleRetake = useCallback(() => {
		setIsGlare(false);
		setIsLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const glareProps = useCallback(
		() => {
			return {
				errorMessage:
					bodyName === 'driverLicenseVerificationSteps' &&
					activeStepName &&
					glareErrors.length > 0
						? verificationContent[activeStepName].errorMessage
						: '',
				image: currentImage,
				label: label,
				glareErrors,
				handleRetake,
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			activeStepName,
			bodyName,
			currentImage,
			glareErrors,
			label,
			verificationContent,
		]
	);

	const cameraLoaderClassName = useCallback(
		(name: string) => {
			return name === 'Use My Camera'
				? 'camera-btn-icon-wrappers'
				: `upload-wrapper ${isLoading ? 'disable-btn-icon-wrappers' : ''}`;
		},
		[isLoading]
	);

	const handleActiveScreen = useCallback((step: string) => {
		setFilledcamerascreen(false);
		setActiveStepName(step);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onClickButton = useCallback(async () => {
		setIsLoading(true);
		const side = (!!formProperties.backImage as any) ? 'back' : 'front';
		const resp = await handleKycDocVerification(side);
		if (resp?.message) {
			setGlareErrors([resp?.message] ?? ['Something went wrong!']);
			setIsGlare(true);
			setFormProperties(content => {
				const key = side == 'back' ? 'backImage' : 'frontImage';
				const data: any = { ...content };
				const keyPayload = key + 'Payload';
				data[key] = '';
				data[keyPayload] = '';
				return data;
			});
		}
		setIsLoading(false);
		return resp;
	}, [
		formProperties.backImage,
		handleKycDocVerification,
		setFormProperties,
		setGlareErrors,
		setIsGlare,
		setIsLoading,
	]);

	const handleSubmitPhoto = useCallback(
		async (formPropertie: any) => {
			const resp = await onClickButton();
			if (resp?.message) return resp;

			if (
				formPropertie.frontImage &&
				!formPropertie.backImage &&
				docType === 'DL'
			) {
				handleActiveScreen('step3');
				return;
			}
		},
		[docType, handleActiveScreen, onClickButton]
	);

	const startCamera = useCallback(async () => {
		try {
			const videoStream = await getVideoStream();
			const newStream = await navigator.mediaDevices.getUserMedia(videoStream);
			if (videoRef.current && captureRef.current) {
				maxCameraTryCountRef.current = 0;
				videoRef.current.srcObject = newStream;
				videoRef.current.play();
				drawRectangle(canvasRef, drawPlaceholder);
				captureRef.current.style.display = 'block';
				if (isMobileDevice()) {
					videoRef.current.setAttribute('autoplay', '');
					videoRef.current.setAttribute('muted', '');
					videoRef.current.setAttribute('playsinline', '');
				}

				videoRef.current.onloadedmetadata = function () {
					setcameraWidth(videoRef.current?.videoWidth ?? 0);
					setcameraHeight(videoRef.current?.videoHeight ?? 0);
				};
			}
		} catch (error) {
			if (maxCameraTryCountRef.current < 4) {
				maxCameraTryCountRef.current = maxCameraTryCountRef.current + 1;
				startCamera();
			} else if (maxCameraTryCountRef.current >= 4) {
				setCamerascreenActive(false);
				errorNotification(
					'Back camera access is unavailable. Please use the upload option to upload the document photos from your device.'
				);
			}
		}
	}, [errorNotification, setCamerascreenActive]);

	const CameraActivefunc = useCallback((key: ImageKey) => {
		//Gaurav: Below code is from default camera not removed due to may be further use.
		setFormProperties(content => {
			const data = { ...content };
			delete data[key];
			data.btnDisabled = true;
			return data;
		});
		setCamerascreenActive(true);
		setFilledcamerascreen(true);
		startCamera();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getDisabled = useCallback(
		(step: string) => {
			const { frontImage, backImage } = formProperties;
			if (step === 'step2') {
				return false;
			} else if (step === 'step3' && frontImage) {
				return false;
			} else if (step === 'step4' && backImage) {
				return false;
			}
			return true;
		},
		[formProperties]
	);

	const captureImage = useCallback(
		// Define a useCallback hook named captureImage
		async (key: ImageKey) => {
			// Declare an asynchronous function with a parameter 'key' of type ImageKey
			reSetCurrentImage(); // Set the current image state to an empty string

			const dataUrl = renderCaptureImage();

			// Check if the data URL is valid
			if (dataUrl) {
				// Check if the captured image is valid
				const isValid = isValidImage('captured-image.png');

				// If the image is valid, process it
				if (isValid) {
					// Process the image data
					const dataImage = await ImageProcess(dataUrl);
					// Set loading flags
					setIsLoading(true);

					const payload = {
						image: formatBase64(dataImage),
					};

					let currentImageUrl = payload?.image;

					if (key !== 'backImage') {
						imageRotateHandle(
							payload,
							rotatePostAPI,
							API_HOST,
							setCurrentImage
						).then(convertedImg => {
							if (convertedImg) {
								currentImageUrl = convertedImg;
							}
						});
						setCurrentImage(currentImageUrl);
					}
					// Update form properties with the processed image data
					setFormProperties(content => {
						const data: any = { ...content };
						const keyPayload = key + 'Payload';
						data[key] = dataImage ?? dataUrl;
						data[keyPayload] = dataURItoBlob(dataUrl, 'captured-image.png');
						data.btnDisabled = false;
						return data;
					});

					// Disable camera screen
					setCamerascreenActive(false);
				} else {
					// Display error notification for invalid image
					errorNotification('Please Select Valid Image');
				}
			}
			setIsLoading(false);
			// Stop the camera
			stopCamera();
		},
		[
			reSetCurrentImage,
			setIsLoading,
			setFormProperties,
			rotatePostAPI,
			API_HOST,
			setCurrentImage,
			errorNotification,
		] // Dependency array for useCallback hook
	);

	const handleOnClickDelete = useCallback(
		(key: ImageKey) => {
			setFormProperties(content => {
				const data = { ...content };
				delete data[key];
				data.btnDisabled = true;
				return data;
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[setFormProperties]
	);

	const handleBackBtn = useCallback(
		(step: string, isGlare: boolean, setBodyName: any) => {
			if (isGlare) {
				setIsGlare(false);
				return;
			}
			if (step === 'step0') {
				resetFormState();
				setBodyName();
			} else {
				setFormProperties(content => {
					const data = { ...content, backImage: '' };
					data.btnDisabled = true;
					return data;
				});
				handleActiveScreen(step);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const handleDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);

	const handleDrop = useCallback(
		(e: DragEvent<HTMLDivElement>) => {
			e.preventDefault();
			e.stopPropagation();
			const files: any = Array.from(e.dataTransfer.files);
			if (
				files[0]?.type !== 'image/png' &&
				files[0]?.type !== 'image/jpeg' &&
				files[0]?.type !== 'image/jpg'
			) {
				errorNotification('File type is not supported!');
				return;
			}
			if (files.length > 1) {
				errorNotification('Multiple file are not accpeted!');
				return;
			}
			onClickUploadButton({ target: { files } }, keyName);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[keyName]
	);

	const data = {
		refs: { canvasRef, captureRef, videoRef },
		states: {
			filledCamerascreen,
			cameraWidth,
			cameraHeight,
			cameraScreenActive,
			verificationContent,
		},
		setStates: { setCamerascreenActive },
		methods: {
			handleOnClickDelete,
			captureImage,
			getDisabled,
			onClickButton,
			CameraActivefunc,
			handleSubmitPhoto,
			cameraLoaderClassName,
			glareProps,
			onClickUploadButton,
			handleBackBtn,
			handleDragOver,
			handleDrop,
			handleActiveScreen,
		},
	};

	return data;
};
