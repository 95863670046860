import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { Input, ReactDropdown } from '@storybook';

import {
	KYB_BUSINESS_VERIFICATION,
	THE_KYB_PROVIDER_TOKEN,
} from 'views/kyb/constants';
import {
	BusinessInformationState,
	CountryStateList,
	CountryStateListState,
	IBuisness_information,
	TheKYBCountryListState,
	TheKYBStateListState,
} from 'views/kyb/stores';
import useFetchWithToken from 'hooks/use-fetch-with-token/use-fetch-with-token';
import { formatValue } from 'utils';
import { APIS } from 'constants/api';
import { useSharedVariables } from 'hooks';
import { OnlyCharNumebrRegex } from 'constants/common';

const maxLength = 15;
const minLength = 9;

export const BusinessVerification = () => {
	// hooks
	const { envHost } = useSharedVariables();
	const { fetchData } = useFetchWithToken();
	// Recoils
	const [formattedCountries, setFormattedCountries] = useRecoilState(
		TheKYBCountryListState
	);
	const [countryListResp, setCountryListResp] = useRecoilState(
		CountryStateListState
	);
	const [businessInformation, setBusinessInformation] = useRecoilState<
		IBuisness_information | any
	>(BusinessInformationState);
	// local states
	const [statesList, setStateList] = useRecoilState(TheKYBStateListState);

	const setFormatedCountryData = useCallback((data: any) => {
		const countryList = data.map((country: { name: string }) => ({
			label: formatValue(country.name ?? '--'),
			value: country.name,
		}));
		setFormattedCountries(countryList);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const fetchDataFromAPI = async () => {
			try {
				const response = await fetchData(
					APIS.COUNTRY_LIST,
					THE_KYB_PROVIDER_TOKEN[envHost as keyof typeof THE_KYB_PROVIDER_TOKEN]
				);

				if (response?.message === 'ok') {
					const { data } = response;
					setCountryListResp(data);
					setFormatedCountryData(data);
				}

				// Do something with the response
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error('Error fetching data:', error);
			}
		};
		if (countryListResp.length === 0) {
			fetchDataFromAPI();
		} else {
			setFormatedCountryData(countryListResp);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countryListResp]);

	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>, key?: string) => {
			const { name, value } = e.target;

			const digits = value?.replace(/[^0-9a-zA-Z]/g, '');
			const feinValue = value
				.replace(/[^0-9a-zA-Z/\- ]/g, '')
				.replace(/\s+/g, ' ');

			if (name === 'fein') {
				if (!OnlyCharNumebrRegex.test(value.trim())) {
					setBusinessInformation((prev: any) => ({
						...prev,
						error: {
							[`error_${name}`]: 'Invalid EIN/TIN',
						},
					}));
				}

				if (
					digits.length > maxLength &&
					OnlyCharNumebrRegex.test(value.trim())
				) {
					setBusinessInformation((prev: any) => ({
						...prev,
						error: {
							[`error_${name}`]:
								'The maximum length for EIN/TIN must be 15 digits',
						},
					}));
					return;
				}
				if (
					digits.length < minLength &&
					OnlyCharNumebrRegex.test(value.trim())
				) {
					setBusinessInformation((prev: any) => ({
						...prev,
						error: {
							[`error_${name}`]:
								'The minimum length for EIN/TIN must be 9 digits',
						},
					}));
				}
				if (
					digits.length <= maxLength &&
					digits.length >= minLength &&
					OnlyCharNumebrRegex.test(value.trim())
				) {
					setBusinessInformation((prev: any) => ({
						...prev,
						error: {
							[`error_${name}`]: '',
						},
					}));
				}

				if (
					digits.length <= maxLength &&
					OnlyCharNumebrRegex.test(value.trim())
				) {
					setBusinessInformation((prev: IBuisness_information) => ({
						...prev,
						[name || (key as string)]: feinValue,
					}));
				}
				return;
			}

			setBusinessInformation((prev: IBuisness_information) => ({
				...prev,
				[name || (key as string)]: value,
			}));
			setBusinessInformation((prev: any) => ({
				...prev,
				error: {
					[`error_${name}`]: '',
				},
			}));

			if (name === 'fein' && value.length !== 9) {
				setBusinessInformation((prev: any) => ({
					...prev,
					error: {
						[`error_${name}`]: 'value must be 9 digits',
					},
				}));
				return;
			}
			if (name === 'fein' && value.length !== 11) {
				setBusinessInformation((prev: any) => ({
					...prev,
					error: {
						[`error_${name}`]: 'value must be 11 digits',
					},
				}));
				return;
			}
		},
		[setBusinessInformation]
	);

	const getOption = useCallback(
		(name: string) => {
			if (name === 'country') {
				return formattedCountries;
			} else return statesList;
		},
		[formattedCountries, statesList]
	);

	const handleChangeSelect = useCallback(
		(event: any, name: string) => {
			setBusinessInformation((prev: IBuisness_information) => ({
				...prev,
				[name]: event.value,
			}));

			if (name === 'country') {
				const selectedCountry: CountryStateList = countryListResp.find(
					country => country.name === event.value
				) as CountryStateList;

				const stateLists = (selectedCountry.states ?? []).map(state => ({
					label: formatValue(state.name ?? '--'),
					value: state.name,
				}));

				if (stateLists.length === 0) {
					setBusinessInformation((prev: IBuisness_information) => {
						const updatedPrev = { ...prev };
						delete updatedPrev.state;
						return updatedPrev;
					});
				} else {
					setBusinessInformation((prev: IBuisness_information) => ({
						...prev,
						state: '',
					}));
				}
				setStateList(stateLists);
			}
		},
		[countryListResp, setBusinessInformation, setStateList]
	);

	const getValue = useCallback(
		(name: string) => {
			if (!businessInformation[name]) {
				return { label: 'Select', value: '' };
			}
			return {
				label: (businessInformation[name] ?? 'Select')
					.replace(/_/g, ' ')
					.toLowerCase()
					.replace(/\b\w/g, (char: string) => char.toUpperCase()),
				value: businessInformation[name] ?? '',
			};
		},
		[businessInformation]
	);

	const renderInputs = useMemo(() => {
		return KYB_BUSINESS_VERIFICATION?.map((el, index: number) => {
			switch (el.type) {
				case 'text':
					return (
						<div
							key={`${el.name}__${index.toString()}`}
							className="kyb-details--col-1"
						>
							<Input
								isRequired={true} // Set isRequired to true for other text inputs
								label={el.label}
								inputType={el.type}
								placeholder={el.placeHolder}
								handleChange={handleChange}
								inputName={el.name}
								value={businessInformation[el.name]}
								key={el.name}
							/>
						</div>
					);
				case 'select':
					return (
						<div
							key={`${el.name}__${index.toString()}`}
							className="kyb-details--col-1"
						>
							<ReactDropdown
								options={getOption(el.name)}
								handleChangeSelect={event => handleChangeSelect(event, el.name)}
								isSearchable={true}
								optionsDropHeight={230}
								label={el.name}
								value={getValue(el.name)}
								placeholder={el.name}
								key={el.name}
								isRequired
							/>
						</div>
					);
				case 'stateSelect':
					return statesList.length > 0 || businessInformation.state ? (
						<div
							key={`${el.name}__${index.toString()}`}
							className="kyb-details--col-1"
						>
							<ReactDropdown
								options={getOption(el.name)}
								handleChangeSelect={event => handleChangeSelect(event, el.name)}
								isSearchable={true}
								label={el.name}
								value={getValue(el.name)}
								optionsDropHeight={230}
								placeholder={el.name}
								key={el.name}
								isRequired
							/>
						</div>
					) : (
						<></>
					);

				case 'fein':
					return (
						<div className="kyb-details__dropdown__wrapper kyb-details--col-1">
							<div
								style={{
									width: '100%',
									height: '100%',
									display: el.name === 'fein' ? 'block' : 'none',
								}}
							>
								<Input
									inputType="text"
									label={`${el.label} EIN/TIN`}
									inputName={el.name}
									placeholder={'EIN/TIN' + ' number'}
									handleChange={handleChange}
									value={businessInformation[el.name]}
									errorMessage={businessInformation.error?.error_fein}
									isError={!!businessInformation.error?.error_fein}
									isRequired={true}
									// handleKeyPress={handleFeinInputLimit}
									autoComplete="off"
									// onPaste={handleFeinInputLimit}
								/>
							</div>
						</div>
					);

				default:
					return <></>;
			}
		});
	}, [
		businessInformation,
		getOption,
		getValue,
		handleChange,
		handleChangeSelect,
		statesList.length,
	]);
	return <>{renderInputs}</>;
};
