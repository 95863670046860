import { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Loader } from '@storybook';

import {
	BuisnessCompanyListState,
	IsBuisnessListFetchedState,
	KYBCompanyFlowState,
	SearchKybCompanyInputState,
	SearchKybCompanyListState,
	SelectedCompanyForKybState,
} from 'views/kyb/stores';
import { formatValue } from 'utils';

import './the-kyb-style.scss';

export const SelectUserComapny = () => {
	// Recoils
	const [selectedCompanyForKyb, setSelectedCompanyForKyb] = useRecoilState(
		SelectedCompanyForKybState
	);
	const setKYBCompanyFlow = useSetRecoilState(KYBCompanyFlowState);
	const isBuisnessListFetched = useRecoilValue(IsBuisnessListFetchedState);
	const companyListState = useRecoilValue(BuisnessCompanyListState);
	const { companies } = companyListState ?? {};

	// this state is only for search input
	const searchCompanyList = useRecoilValue(SearchKybCompanyListState);
	const searchKybCompanyInput = useRecoilValue(SearchKybCompanyInputState);

	const updateFlow = useCallback((data: any, type: any) => {
		setSelectedCompanyForKyb(data);
		setKYBCompanyFlow(type);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderCompanyList = useMemo(() => {
		const companyList =
			searchKybCompanyInput.length >= 1
				? searchCompanyList?.companies
				: companies;

		return companyList.map((company: any) => (
			<div
				onClick={() => updateFlow(company, 'select')}
				key={company.id}
				className="kyb-list__card-wrapper"
			>
				<input
					type="radio"
					name="selectCard"
					checked={selectedCompanyForKyb.id === company.id}
					className="kyb-list__radio-button"
				/>
				<div className="kyb-list__name">{company.name ?? '--'}</div>
				<div className="kyb-list__address-wrapper">
					<div className="kyb-list__address">TIN </div>
					<div className="kyb-list__address-value">
						<span className="kyb-list__collen">:</span>
						{company?.tin ?? '--'}
					</div>
				</div>

				<div className="kyb-list__address-wrapper">
					<div className="kyb-list__address">Status </div>
					<div className="kyb-list__address-value">
						<span className="kyb-list__collen">:</span>
						{company?.isActive ? (
							<span className="kyb-list__dot-green"></span>
						) : (
							<span className="kyb-list__dot-red"></span>
						)}

						{formatValue(company?.isActive ? 'Active' : 'Inactive' ?? '--')}
					</div>
				</div>
				<div className="kyb-list__address-wrapper">
					<div className="kyb-list__address">Address </div>
					<div className="kyb-list__address-value">
						<span className="kyb-list__collen">:</span>{' '}
						{formatValue(company?.address ?? '--')}
					</div>
				</div>
			</div>
		));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		selectedCompanyForKyb,
		searchKybCompanyInput.length,
		searchCompanyList?.companies,
	]);

	const renderLoader = useMemo(() => {
		return (
			<>
				{!isBuisnessListFetched && (
					<div className="kyb-list__loader-wrapper">
						<div className="kyb-list__loader-box">
							<Loader type="loader" dimension={20} />{' '}
						</div>
						<div>Please wait fetching more companies.</div>
					</div>
				)}
				<div
					onClick={() =>
						updateFlow(
							{
								id: 'manual',
								name: '',
								registrationCode: '',
								address: '',
								status: '',
								type: '',
							},
							'manual'
						)
					}
					className="kyb-list__card-wrapper"
				>
					<input
						type="radio"
						name="selectCard"
						checked={selectedCompanyForKyb.id === 'manual'}
						className="kyb-list__radio-button"
					/>
					<div className="kyb-list__details-wrapper">
						<div className="kyb-list__name-wrapper">
							<div className="kyb-list__name">Company not listed</div>
							<div className="kyb-list__card-desp">
								You can add company details manually.
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}, [isBuisnessListFetched, selectedCompanyForKyb.id, updateFlow]);

	return (
		<div className="kyb-list">
			{renderCompanyList}
			{renderLoader}
		</div>
	);
};
