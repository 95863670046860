/* eslint-disable no-console */
import { useEffect, useRef, useCallback } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	REACT_APP_ENROLL_CUSTOM_SERVERS,
	REACT_APP_ENROLL_WEBRTC_URL,
} from 'envs';

import { VIDEO_SETTINGS } from './constants';
import { SessionDetailsState } from 'hooks/use-next-step/stores';
import { APIS } from 'constants/api';
import {
	CameraStreamingState,
	EnrollIceServerListState,
	IceServers,
	IsWebRtcConnectionEnabledState,
	PeerConnectionState,
} from './states';

interface CustomRTCRtpEncodingParameters extends RTCRtpEncodingParameters {
	scalabilityMode?: string;
}

const usePeerConnection = () => {
	// Local State ---
	const [peerConnection, setPeerConnection] =
		useRecoilState<RTCPeerConnection | null>(PeerConnectionState);

	// Refs
	const [CameraStreaming, setCameraStreaming] =
		useRecoilState(CameraStreamingState);
	const localStream = useRef<MediaStream | null>(null);

	//Global State ---
	const sessionDetails = useRecoilValue(SessionDetailsState);
	const sessionDetailsRef = useRef(sessionDetails);
	const setIsWebRtcConnectionEnabled = useSetRecoilState(
		IsWebRtcConnectionEnabledState
	);
	const enrollIceServerList = useRecoilValue<IceServers>(
		EnrollIceServerListState
	);

	useEffect(() => {
		sessionDetailsRef.current = sessionDetails;
	}, [sessionDetails]);

	const negotiate = useCallback(
		async (pc: RTCPeerConnection, enrollType: string) => {
			console.log('Creating Offer--->', pc);
			return pc
				?.createOffer()
				.then(function (offer) {
					console.log('Creating Offer-confirmation---->');
					return pc.setLocalDescription(offer);
				})
				.then(function () {
					// wait for ICE gathering to complete
					return new Promise<void>(resolve => {
						function checkState() {
							if (pc.iceGatheringState === 'complete') {
								pc.removeEventListener('icegatheringstatechange', checkState);
								resolve();
							}
						}
						pc.addEventListener('icegatheringstatechange', checkState);
					});
				})
				.then(function () {
					console.log('Sending offer to signalling server ---->');
					const offer = pc.localDescription;
					const metadata = JSON.stringify({
						webRtcSessionId: sessionDetailsRef.current?.sessionId ?? '',
						nodeId: sessionDetailsRef.current?.nodes?._id ?? '',
						enrollType: enrollType,
					});
					console.log('metadata--->', metadata);
					return fetch(REACT_APP_ENROLL_WEBRTC_URL + APIS.WEBRTC_CONNECTION, {
						body: JSON.stringify({
							sdp: offer?.sdp,
							type: offer?.type,
							video_transform: 'none',
							metadata,
						}),
						headers: {
							'Content-Type': 'application/json',
						},
						method: 'POST',
					});
				})
				.then(function (response: { json: () => any }) {
					console.log('response', response);
					setIsWebRtcConnectionEnabled(true);
					return response.json();
				})
				.then(function (answer: any) {
					console.log('Got answer', answer);
					setIsWebRtcConnectionEnabled(true);
					return pc.setRemoteDescription(answer);
				})
				.catch(function (e: any) {
					console.error(e);
					setIsWebRtcConnectionEnabled(true);
				});
		},

		[setIsWebRtcConnectionEnabled]
	);

	const createPeerConnection = useCallback(
		async (enrolType: string) => {
			try {
				// Get user media
				const stream =
					await navigator?.mediaDevices.getUserMedia(VIDEO_SETTINGS);
				const pc = new RTCPeerConnection({
					iceServers:
						enrollIceServerList.length !== 0
							? (enrollIceServerList as any)
							: JSON.parse(REACT_APP_ENROLL_CUSTOM_SERVERS),
				});
				pc.restartIce();
				localStream.current = stream;
				setCameraStreaming(stream);
				// Set local stream to peer connection
				stream?.getTracks().forEach(track => pc?.addTrack(track, stream));

				// Configure VP9 SVC
				const sender = peerConnection
					?.getSenders()
					.find(s => s.track?.kind === 'video');
				if (sender) {
					const params = sender.getParameters();
					(params.encodings as CustomRTCRtpEncodingParameters[]).push({
						rid: 'q',
						networkPriority: 'high',
						priority: 'high',
						scalabilityMode: 'L1T3',
					});
					sender.setParameters(params);
				}

				// Handle negotiation and set local description
				await negotiate(pc, enrolType); // Implement negotiate function as per your application logic

				// Set local description after negotiation
				const localDescription = await pc.createOffer();
				await pc.setLocalDescription(localDescription);

				// Handle ICE candidates
				pc.onicecandidate = event => {
					if (event.candidate) {
						// Send event.candidate to the remote peer via your signaling mechanism
						console.log('ICE candidate:', event.candidate);
					}
				};
				// Update Recoil state with the peer connection instance
				setPeerConnection(pc);
			} catch (error) {
				console.error('Error creating peer connection:', error);
			}
		},
		[
			enrollIceServerList,
			negotiate,
			peerConnection,
			setCameraStreaming,
			setPeerConnection,
		]
	);

	const recreatePeerConnection = useCallback(
		async (enrolType: string) => {
			// Close the current peer connection if it exists
			if (peerConnection) {
				peerConnection.close();
				setPeerConnection(null);
			}
			// Recreate the peer connection
			await createPeerConnection(enrolType);
		},
		[createPeerConnection, peerConnection, setPeerConnection]
	);

	useEffect(() => {
		if (peerConnection) {
			const handleIceConnectionStateChange = () => {
				console.log('ICE Connection State:', peerConnection.iceConnectionState);

				if (peerConnection.iceConnectionState === 'connected') {
					// Connection is successful
					console.log('Connection successful! --->');
				} else if (peerConnection.iceConnectionState === 'failed') {
					console.log('RESTARTING ....');
					peerConnection.restartIce();
					recreatePeerConnection('face');
				}
			};

			peerConnection.addEventListener(
				'iceconnectionstatechange',
				handleIceConnectionStateChange
			);

			return () => {
				peerConnection.removeEventListener(
					'iceconnectionstatechange',
					handleIceConnectionStateChange
				);
			};
		}

		// Explicitly return undefined when peerConnection is null or undefined
		return undefined;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [peerConnection]);

	const closePeerConnection = useCallback(() => {
		// Close local media stream
		if (CameraStreaming) {
			CameraStreaming.getTracks().forEach(track => track.stop());
			localStream.current = null;
		}
		// Close peer connection
		if (peerConnection) {
			peerConnection.close();
			setPeerConnection(null);
		}

		console.log('WebRTC connection closed.');
	}, [CameraStreaming, peerConnection, setPeerConnection]);

	return { createPeerConnection, closePeerConnection };
};

export default usePeerConnection;
